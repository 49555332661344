import { StoreonModule } from 'storeon';
import { useStoreon } from 'storeon/react';
import { client, GET_MERCHANT } from 'api';
import { Merchant } from 'types';

export interface MerchantState {
  merchant?: Merchant;
  loading: boolean;
}

export interface MerchantEvents {
  'merchant/set': MerchantState,
  'merchant/fetch': undefined,
}

export const merchantModule: StoreonModule<MerchantState, MerchantEvents> = store => {
  store.on('@init', async () => {
    store.dispatch('merchant/fetch');
  });

  store.on('merchant/set', (store, { merchant, loading }) => ({
    ...store.merchant,
    merchant,
    loading,
  }));

  store.on('merchant/fetch', async () => {
    try {
      store.dispatch('merchant/set', { loading: true });

      const { data } = await client.request<{ merchant: Merchant }>({ query: GET_MERCHANT });

      const merchant = data?.merchant;

      store.dispatch('merchant/set', { merchant, loading: false });
    } catch (err) {
      console.log(err);
      store.dispatch('merchant/set', { loading: false });
    }
  });
};

export const useMerchant = () => {
  return useStoreon<MerchantState, MerchantEvents>('merchant', 'loading');
};

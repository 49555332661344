const merchantFragment = `
  id
  email
  emailVerified
  createdAt
  firstname
  lastname
  timezoneID
  avatar
`;

export const GET_MERCHANT = `
  query Merchant {
    merchant {
      ${merchantFragment}
    }
  }
`;

export const GET_MERCHANT_EMAIL = `
  query MerchantEmail {
    merchant {
      email
    }
  }
`;

export const GET_ACCOUNT_INFO = `
  query AccauntInfo {
    merchant {
      ${merchantFragment}
    }
    languages {
      id
      code
    }
    timezones {
      id
      title
    }
  }
`;

export const CHANGE_EMAIL = `
  mutation MerchantEmailUpdate($email: String!, $currentPassword: String!) {
    merchantEmailUpdate(input: { email: $email, currentPassword: $currentPassword }) {
        emailConfirmationToken
        error {
          code
          message
        }
    }
  } 
`;

export const CHANGE_PASSWORD = `
  mutation ChangePassword ($oldPassword: String!, $newPassword: String!) {
    merchantUpdate (input: {
      password: { 
        oldPassword: $oldPassword,
        newPassword: $newPassword 
      }
    }) {
      ${merchantFragment}
    }
  }
`;

export const CHANGE_ACCOUNT = `
  mutation ChangeAccount ($firstName: String, $lastName: String, $timezoneID: ID) {
    merchantUpdate (input: { 
      firstname: $firstName 
      lastname: $lastName 
      timezoneID: $timezoneID 
    }) {
      ${merchantFragment}
    }
  }
`;

export const CHANGE_AVATAR = `
  mutation ChangeAvatar ($src: Upload!) {
    merchantAvatarUpdate(input: { src: $src }) {
      ${merchantFragment}
    }
  }
`;

export const DELETE_AVATAR = `
  mutation DeleteAvatar {
    merchantAvatarDelete {
      ${merchantFragment}
    }
  }
`;

/* eslint-disable max-len */
export default {
  translation: {
    errors: {
      email_already_used: 'Account already exists',
      password_was_used: 'Password was used in the past',
      empty_field: 'Empty field',
      long_field: 'Field value is too long',
      unknown_error: 'Unknown error',
      wrong_password: 'Wrong password',
      internal_server_error: {
        title: 'Internal server error',
        desc: 'The server faced an error and could not complete your request, please try later.',
      },
      wrong_login_or_password: 'Incorrect login or password',
      store_name_already_exists: 'Store name already exists',
      incorrect_password: 'Incorrect password',
      validation_error: {
        email: 'Incorrect email',
        email_already_exist: 'Email already exists',
        password: 'Password isn’t strong enough',
      },
      wrong_format: 'Incorrect format',
    },
    links: {
      features: 'Features',
      examples: 'Examples',
      resources: 'Resources',
      home: 'Home',
      pricing: 'Pricing',
    },
    languages: {
      eng: 'English',
    },
    totally_free: 'totally free',
    edit: 'Edit',
    document_layout: {
      navbar_title: 'Content',
    },
    email: 'Email',
    email_was_confirmed: 'Email was successfully confirmed',
    not_verified: 'not verified',
    password: 'Password',
    name: 'Name',
    firstName: 'First name',
    lastName: 'Last name',
    have_read_and_agree: 'I have read and agree to the ',
    and: 'and',
    terms_of_use: 'Terms of Use',
    privacy_policy: 'Privacy Policy',
    cookie_policy: 'Cookie Policy',
    contact_us: 'Contact Us',
    back: 'Back',
    account_settings: 'Account',
    close: 'Close',
    creation_date: 'Creation date',
    delete: 'Delete',
    deleted: 'deleted',
    copyright: 'Unistore 2020 – {{year}}, all rights reserved',
    create_store: 'Create store',
    current: 'Current',
    cancel: 'Cancel',
    change_password: 'Change Password',
    current_password: 'Current password',
    change_email: 'Change Email',
    update_avatar: 'Update avatar',
    upload_avatar: 'Upload avatar',
    new_email: 'New email',
    continue: 'Continue',
    forgot_password_button: 'Forgot password',
    reset_password: 'Reset Password',
    update: 'Update',
    avatar: 'avatar',
    avatar_was_updated: 'Avatar was updated',
    avatar_was_deleted: 'Avatar was deleted',
    upload: 'Upload',
    save: 'Save',
    status: 'Status',
    share_access: 'Share access',
    changes_saved: 'Changes were saved',
    changes_save_error: 'Error when saving changes',
    draft: 'Draft',
    delete_avatar_title: 'Delete avatar',
    delete_avatar_description: 'Are you sure you want to delete your avatar?',
    timezone: 'Timezone',
    language: 'Language',
    log_out: 'Log out',
    log_out_description: 'Are you sure you want to leave the service?',
    my_first_store: 'My first store',
    store_name: 'Store name',
    store_url: 'Store URL',
    edit_store: 'Edit store',
    edit_store_url_hint: '– minimum 2 and maximum 63 characters\n– digits (0 – 9), letters (a – z), hyphen (-) are allowed\n– all spaces will be replaced with hyphens',
    store_was_created: 'Store was created',
    store_updated: 'Store was updated',
    role: 'Role',
    store: 'store',
    stores: 'stores',
    partner_portal: 'Partner portal',
    browse: 'Browse',
    you_havent_created_yet: 'You haven’t created any {{name}} yet',
    become_our_partner: 'Become our partner and sell your themes in our store.',
    explore: 'Explore',
    crop_image: {
      title: 'Adjust image',
      description: 'Zoom in and out to adapt your image',
      save_image: 'Save image',
    },
    check_mailbox: {
      title: 'Check your mailbox',
      description: 'We’ve sent you an email to confirm your new mailbox.',
    },
    password_was_changed: {
      title: 'Your password was changed',
      description: 'You’ve successfully changed your password.',
    },
    sign_in: {
      title: 'Sign In',
      description: 'We are glad too see you again!',
      remember_me: 'Remember me',
      continue: 'Continue',
      forgot_password: 'Forgot your password?',
      not_me: 'It’s not me',
    },
    sign_up: {
      title: 'Sign up',
      description_step1: 'Use Unistore solution to start and <1>grow your online business</1> all over the world.',
      description_step2: '<0>Create your store</0> and get access to Unistore right now.',
      password_description: 'Password must consist of 7+ characters and contain digit, special symbol, and uppercase letter',
      create_account: 'Create Account',
    },
    password_reset: {
      title: 'Password reset',
      description: 'Enter the email you used to create your account. We will send you all the instructions to reset your password.',
      send_email: 'Send Email',
    },
    check_your_mailbox: {
      title: 'Check your mailbox',
      description: 'You will receive instructions on how to reset your password within a few minutes. If you had not received these instructions, please, check that you used the correct e-mail address.',
      ok_i_see: 'Ok, I see',
    },
    new_password: {
      title: 'New password',
      description: 'Please enter a new password for your {{platformName}} account.',
      set_new_password: 'Set New Password',
    },
    link_expired: {
      title: 'Link expired',
      description: 'Oops! Your link to set a new email address has been expired! Please make a new request.',
      reset_password_again: 'Reset Password Again',
    },
    start_for_free: 'Start for Free',
    general: {
      profile_details: 'Profile details',
      language_and_time: 'Language and time',
    },
    attention: {
      title: 'Attention!',
      description_reset: 'To reset your password you will be logged out and forwarded to a password reset page.',
      description_wrong_password: 'Your current password isn’t matching. If you need to reset it, please log out and go to reset password page.',
    },
    cli_tool_card: {
      title: 'Access tokens',
      description: 'Manage access tokens for your Unistore account.',
      learn_more: 'Learn More',
    },
    cli_tool: {
      title: 'Access tokens',
      description: 'Use tokens to manage your stores through Unistore CLI and other integrations. Keep your tokens secure and change them periodically.',
      create_button: 'Generate Token',
      table_token_name: 'Token name',
      table_updated: 'Date updated',
      field_token_label: 'Token name',
      maximum_tokens: 'You have reached the limit of 5 tokens.',
      edit_popup_title: 'Edit token name',
      edit_popup_button: 'Save',
      create_popup_title: 'Generate token',
      create_popup_button: 'Generate Token',
      delete_popup_title: 'Delete token',
      delete_popup_description: 'You are sure that you want to delete this token? You will not be able to use it to access your Unistore account.',
      token_creation_title: 'Token was generated',
      token_creation_description: 'Make sure to copy your access token now. You will not be able to copy it again.',
      copy_token: 'Copy Token',
      token_was_created: 'Token was created',
      token_was_updated: 'Token name was updated',
      token_was_deleted: 'Token was deleted',
      token_was_copied: 'Token was copied',
      errors: {
        VALIDATE: 'Title is not valid',
        LIMIT_EXCEEDED: 'Tokens limit exceeded',
        NOT_FOUND: 'Token not found',
      },
    },
    routes: {
      // settings
      general: 'General',
      stores: 'My stores',
      security: 'Security',
      extensions: 'Extensions',
      cookie_policy: 'Cookie policy',
      // knowledge_base
      knowledge_base: 'Knowledge base',
    },
  },
};

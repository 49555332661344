import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Button, TextButton } from '@unistorecom/ui';
import { AppRoutes } from 'const';

interface Props {
  onClick?: () => void;
}

const Actions = (props: Props) => {
  const { onClick } = props;
  const { t } = useTranslation();
  const { push } = useHistory();
  const handleSignIn = () => push(AppRoutes.SIGN_IN);
  const handleStartForFree = () => push(AppRoutes.SIGN_UP);

  return (
    <div className="Header__Actions" onClick={onClick}>
      <TextButton onClick={handleSignIn}>
        {t('sign_in.title')}
      </TextButton>
      <Button
        className="Header__Button"
        variant="primary"
        onClick={handleStartForFree}
        size="medium"
      >
        {t('start_for_free')}
      </Button>
    </div>
  );
};

const areEqual = (prev: Props, next: Props) => prev === next;

export default React.memo(Actions, areEqual);
